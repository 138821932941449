interface IFinancialOptions {
  hideCents?: boolean
}

export const financial = ({ hideCents }: IFinancialOptions = {}) => (cents: number) =>
  `${Number.parseFloat(`${cents / 100}`).toFixed(hideCents ? 0 : 2)}`

export const displayPriceHideCents = (displayPrice: string) => displayPrice.replace(/\.\d\d$/, '')
export const displayPriceHideCentsIfZero = (price: string) => price.replace(/\.00$/, '')
export const displayPriceHideCentsCurrency = (price: string, currency: string) => {
  // These currencies don't have cents per @Ray.su
  if (currency === 'JPY' || currency === 'KRW') {
    return price.replace(/\.00$/, '')
  }
  return price
}

import Bugsnag from '@bugsnag/js'

import { useShoppingRegionContext } from 'shared/contexts/ShoppingRegionContextProvider'
import { useUser } from 'shared/hooks/useUser'

export const useTikTokPixelTrackEvent = () => {
  const { currencyCode } = useShoppingRegionContext()
  const { currentUser } = useUser()

  const trackTikTokEvent = (type: string, params = {}) => {
    // NOTE: Do not fire if tiktok is undefined and one trust advertising category is blocked
    if (!window?.ttq || !window?.OptanonActiveGroups?.includes('C0004')) return

    try {
      if (currentUser?.email) {
        window?.ttq?.identify({ email: currentUser?.email })
      }
      window?.ttq?.track(type, params)
    } catch (error) {
      Bugsnag.notify(error as Error, (event) => {
        event.context = 'TikTok Pixel Track Event'
        event.severity = 'warning'
      })
    }
  }

  const trackTikTokCheckoutAddCart = ({ contentCategory, contentId, price, quantity }) => {
    trackTikTokEvent('AddToCart', {
      content_category: contentCategory,
      content_id: contentId,
      content_type: 'product',
      currency: currencyCode,
      price: price && price * 0.01,
      quantity,
      value: price && price * 0.01,
    })
  }

  const trackTikTokCheckoutPlaceOrder = ({
    contentCategory,
    contentId,
    price,
    quantity,
    value,
  }) => {
    trackTikTokEvent('PlaceAnOrder', {
      content_category: contentCategory,
      content_id: contentId,
      content_type: 'product',
      currency: currencyCode,
      price: price && price * 0.01,
      quantity,
      value: value && value * 0.01,
    })
  }

  const trackTikTokCheckoutPurchase = ({ contentCategory, contentId, price, quantity, value }) => {
    trackTikTokEvent('CompletePayment', {
      content_category: contentCategory,
      content_id: contentId,
      content_type: 'product',
      currency: currencyCode,
      price: price && price * 0.01,
      quantity,
      value: value && value * 0.01,
    })
  }

  const trackTikTokPDPView = ({ contentCategory, contentId, contentName, price }) => {
    trackTikTokEvent('ViewContent', {
      content_category: contentCategory,
      content_id: contentId,
      content_name: contentName,
      content_type: 'product',
      currency: currencyCode,
      price: price && price * 0.01,
      value: price && price * 0.01,
    })
  }

  return {
    trackTikTokCheckoutAddCart,
    trackTikTokCheckoutPlaceOrder,
    trackTikTokCheckoutPurchase,
    trackTikTokPDPView,
  }
}

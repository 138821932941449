import styled, { css } from 'styled-components'
import { ChangeEvent, useContext } from 'react'
import { colors, fonts, media } from 'shared/lib'
import { isNil as _isNil } from 'lodash'

import RadioGroupView from 'checkout/enums/RadioGroupView'
import { RadioButtonOption } from 'shared/icons/RadioButtonOption'
import { RadioButtonSelected } from 'shared/icons/RadioButtonSelected'
import { RadioGroupContext } from 'checkout/components/RadioGroup'

type RadioButtonProps = {
  checked: boolean
  children: React.ReactNode[] | React.ReactNode
  className?: string
  disabled: boolean
  displayRadioButton: boolean
  isMobile: boolean
  marginBetween?: string
  onClick(event: MouseEvent): void
  onRadioSelect(event: ChangeEvent): void
  qaAttr?: string
  renderTitle: React.ReactNode[] | React.ReactNode
  subtitle: React.ReactNode[] | React.ReactNode
  tags: React.ReactNode[] | React.ReactNode
  title: string | React.ReactNode
  value: string
}

const RadioButton = ({
  checked,
  children,
  className,
  disabled,
  displayRadioButton = false,
  marginBetween,
  onClick,
  onRadioSelect,
  qaAttr,
  renderTitle,
  subtitle,
  tags,
  title,
  value,
}: Partial<RadioButtonProps>) => {
  const { name, view } = useContext(RadioGroupContext)
  return (
    <RadioButton.Label
      $checked={checked}
      $disabled={disabled}
      $displayRadioButton={displayRadioButton}
      $marginBetween={marginBetween}
      $view={view}
      className={className}
      htmlFor={value}
    >
      <RadioButton.Header>
        <RadioButton.RadioBox>
          {displayRadioButton && checked && <RadioButtonSelected />}
          {displayRadioButton && !checked && <RadioButtonOption />}
          <RadioButton.Radio
            $displayRadioButton={displayRadioButton}
            checked={checked}
            data-qa={`${qaAttr}RadioLabel`}
            data-testid={`radioButton${value}`}
            disabled={disabled}
            id={value}
            name={name}
            onChange={onRadioSelect}
            onClick={onClick}
            type="radio"
            value={value}
          />
          <RadioButton.Text>
            {title && (
              <RadioButton.Title data-qa={`${qaAttr}RadioLabelTitle`}>{title}</RadioButton.Title>
            )}
            {subtitle && (
              <RadioButton.Subtitle data-qa={`${qaAttr}RadioLabelSubTitle`}>
                {subtitle}
              </RadioButton.Subtitle>
            )}
            {renderTitle && renderTitle}
          </RadioButton.Text>
        </RadioButton.RadioBox>
        {tags}
      </RadioButton.Header>
      {children && checked && <RadioButton.Content>{children}</RadioButton.Content>}
    </RadioButton.Label>
  )
}

RadioButton.Content = styled.div`
  margin-top: 20px;
`
RadioButton.Header = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  min-width: 100%;
  width: 100%;
`
RadioButton.Label = styled.label<{
  $checked?: boolean
  $disabled?: boolean
  $displayRadioButton: boolean
  $marginBetween?: string
  $view: RadioGroupView
}>`
  border-radius: 1px;
  border: 1px solid ${({ $checked }) => ($checked ? colors.FC2_BLACK : colors.FC2_LIGHTEST_GREY)};
  display: flex;
  flex-direction: column;
  position: relative;
  ${media.large`
    :hover {
      border: 1px solid ${colors.FC2_DARK_GREY};
      cursor: pointer;
    }
  `}
  ${({ $disabled }) =>
    $disabled &&
    css`
      border-color: ${colors.FC2_LIGHTEST_GREY};
      p,
      span,
      div {
        color: ${colors.FC2_LIGHT_GREY};
      }
      :hover {
        border: 1px solid ${colors.FC2_LIGHTEST_GREY};
        cursor: default;
      }
    `}
  ${({ $displayRadioButton, $marginBetween, $view }) => {
    if ($view === RadioGroupView.List) {
      return css`
        margin: 20px 5px;
        padding: 10px;
        width: 100%;
      `
    }
    if ($displayRadioButton) {
      return css`
        margin: ${$marginBetween ? $marginBetween : '12px 0'};
        padding: 24px;
        width: 100%;
      `
    }
    return css`
      padding: 25px 20px;
      margin: 15px 0;
    `
  }}
`
RadioButton.Radio = styled.input`
  height: 12px;
  opacity: 0;
  position: absolute;
  width: 12px;
`
RadioButton.RadioBox = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;
`
RadioButton.Subtitle = styled.div`
  font-size: 10px;
  margin-top: 5px;
  color: ${({ color }) => color || colors.FC2_GREY};
`
RadioButton.Text = styled.div`
  ${fonts.BODY_TEXT};
  color: ${colors.FC2_BLACK};
  width: 100%;
`
RadioButton.Title = styled.div`
  ${fonts.SUBTITLE_3};
`

export default RadioButton

import styled from 'styled-components'

export const RadioButtonSelected = () => {
  return (
    <CustomSvg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Select">
        <path
          id="Vector"
          d="M10.5 6C10.5 8.20914 8.70914 10 6.5 10C4.29086 10 2.5 8.20914 2.5 6C2.5 3.79086 4.29086 2 6.5 2C8.70914 2 10.5 3.79086 10.5 6Z"
          fill="white"
          stroke="black"
          strokeWidth="4"
        />
      </g>
    </CustomSvg>
  )
}

const CustomSvg = styled.svg`
  margin-right: 16px;
`

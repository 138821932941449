import { FC } from 'react'
import styled from 'styled-components'
import { fonts, colors } from 'shared/lib'

const BadgeBox = styled.div<{ badgeType?: string; rightAligned?: boolean; priceAligned?: boolean }>`
  ${fonts.CAPTION};
  ${({ priceAligned }) => (priceAligned ? 'position: relative;' : 'position: absolute;')}
  ${({ priceAligned }) => (priceAligned ? 'bottom: 2px;' : 'top: 15px;')}
  ${({ priceAligned }) => (priceAligned ? 'left: 10px;' : '')}
  ${({ rightAligned }) => (rightAligned ? 'right: 15px;' : '')}
  font-style: italic;
  font-weight: bold;
  padding: 2px 7px;
  display: inline-block;
  color: ${({ badgeType }) => (badgeType === 'primary2' ? colors.FC2_WHITE : colors.FC2_BLACK)};
  background-color: ${({ badgeType }) =>
    badgeType === 'primary2' ? colors.FC2_RED : colors.FC2_LIGHTEST_GREY};
`

type BadgeType = 'primary1' | 'primary2'

interface IBadge {
  text: string
  badgeType?: BadgeType
  qaAttr?: string
  rightAligned?: boolean
  priceAligned?: boolean
}

export const Badge: FC<IBadge> = ({
  text,
  badgeType = 'primary1',
  qaAttr,
  rightAligned = false,
  priceAligned = false,
}) => (
  <BadgeBox data-qa={qaAttr} badgeType={badgeType} rightAligned={rightAligned} priceAligned={priceAligned}>
    {text}
  </BadgeBox>
)

export default Badge

import styled from 'styled-components'

export const RadioButtonOption = () => {
  return (
    <CustomSvg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Select">
        <g id="Vector">
          <path
            d="M6.5 12C9.81371 12 12.5 9.31371 12.5 6C12.5 2.68629 9.81371 0 6.5 0C3.18629 0 0.5 2.68629 0.5 6C0.5 9.31371 3.18629 12 6.5 12Z"
            fill="white"
          />
          <path
            d="M12 6C12 9.03757 9.53757 11.5 6.5 11.5C3.46243 11.5 1 9.03757 1 6C1 2.96243 3.46243 0.5 6.5 0.5C9.53757 0.5 12 2.96243 12 6Z"
            stroke="black"
            strokeOpacity="0.4"
          />
        </g>
      </g>
    </CustomSvg>
  )
}

const CustomSvg = styled.svg`
  margin-right: 16px;
`

import { createContext, FC } from 'react'
import styled from 'styled-components'
import RadioGroupView from 'checkout/enums/RadioGroupView'

export const RadioGroupContext = createContext({
  name: '',
  view: RadioGroupView.Default,
})

interface IGroupPositionerProps {
  view: RadioGroupView
}

const GroupPositioner = styled.div`
  ${({ view }: IGroupPositionerProps) => (view === RadioGroupView.List ? 'display: flex;' : '')}
`

interface RadioGroupProps {
  name: string
  view?: RadioGroupView
  className?: string
  qaAttr?: string
}

const RadioGroup: FC<RadioGroupProps> = ({ name, view, children, className, qaAttr }) => (
  <GroupPositioner data-qa={qaAttr} view={view} className={className}>
    <RadioGroupContext.Provider value={{ name, view }}>{children}</RadioGroupContext.Provider>
  </GroupPositioner>
)

export default RadioGroup

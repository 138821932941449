import { apiSlice } from 'api/apiSlice'
import { PriceBreakdown } from 'shared/types/PriceBreakdown'

type GetPriceBreakdownParams = {
  boxCondition: string
  countryCode: string
  isInstantShip: boolean
  priceCents: number
  productId: string
  productTemplateId: string
  shoeCondition: string
  size: string
}

const productVariantsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getBuyBarCopy: build.query({
      query: ({ slug, country }) => ({
        app: 'sneakers',
        method: 'GET',
        url: `/web-api/v1/product_variants/buy_bar_copies?productTemplateId=${slug}&countryCode=${country}&timestamp=${new Date().getTime()}`,
      }),
    }),
    getPrices: build.query({
      query: (params) => ({
        app: 'sneakers',
        method: 'GET',
        params,
        url: `/web-api/v1/product_variants`,
      }),
    }),
    getPriceBreakdown: build.query<PriceBreakdown, GetPriceBreakdownParams>({
      query: (params) => ({
        app: 'sneakers',
        method: 'GET',
        params,
        url: `/web-api/v1/product_variants/price_breakdown`,
      }),
    }),
  }),
})

export const {
  useGetBuyBarCopyQuery,
  useGetPricesQuery,
  useGetPriceBreakdownQuery,
  useLazyGetPriceBreakdownQuery,
} = productVariantsApi

import Bugsnag, { NotifiableError } from '@bugsnag/js'
import { convertToDollars } from 'shared/lib/formatCurrency'
import * as Yup from 'yup'
import { getMParticle } from './mParticleService'

interface ICreateProductData {
  name: string
  sku: string
  priceCents: number
  brandName?: string
  condition?: string
}

interface IOrderForLogPurchase {
  number: number
  priceCents: number
}

/**
 * mParticle ecommerce events, these event automatically map to events needed for 3rd
 * party data tracking integrations (Facebook and Google Analytics)
 * https://docs.mparticle.com/developers/sdk/web/commerce-tracking/
 */

// calls createProduct event in mParticle (needed for many of the ecom events)
export function createProduct(data: ICreateProductData, mparticle: any) {
  const { name, sku, priceCents, brandName, condition = 'new' } = data
  const quantity = 1

  const createProductSchema = Yup.object({
    priceCents: Yup.number()
      .min(1)
      .required(
        `Price cents (${priceCents}) not supplied as number to mParticle create product, pt: ${name}, price is required`,
      ),
    sku: Yup.string()
      .min(1)
      .required(`Sku not supplied to mParticle create product, pt: ${name}, sku is required`),
    name: Yup.string()
      .min(1)
      .required(`Name not supplied to mParticle create product, sku: ${sku}, name is required`),
  })

  try {
    createProductSchema.validateSync(data)
  } catch (e) {
    Bugsnag.notify(new Error((e.errors as string[]).join(', ')))
    return
  }

  const price = convertToDollars(priceCents) || 0
  try {
    // name, sku, price required for mParticle createProduct
    return mparticle.eCommerce.createProduct(name, sku, price, quantity, brandName, condition)
  } catch (error) {
    Bugsnag.notify(error as NotifiableError, (event) => {
      event.context = 'mParticle createProduct'
    })
  }

  return
}

export function logViewDetail(product: ICreateProductData) {
  const mParticle = getMParticle()

  if (!mParticle) {
    return
  }

  window.mParticle.ready(() => {
    const mParticleProduct = createProduct(product, window.mParticle)

    if (mParticleProduct) {
      try {
        window.mParticle.eCommerce.logProductAction(
          window.mParticle.ProductActionType.ViewDetail,
          mParticleProduct,
          {
            content_type: 'product_group',
          },
        )
      } catch (e) {
        Bugsnag.notify(e as NotifiableError, (event) => {
          event.context = 'mParticle logViewDetail'
        })
      }
    }
  })
}

export function logAddToCart(product: ICreateProductData) {
  const mParticle = getMParticle()

  if (!mParticle) {
    return
  }

  const mParticleProduct = createProduct(product, mParticle)
  if (mParticleProduct) {
    try {
      mParticle.eCommerce.logProductAction(
        mParticle.ProductActionType.AddToCart,
        mParticleProduct,
        { content_type: 'product_group' },
      )
    } catch (e) {
      Bugsnag.notify(e as NotifiableError, (event) => {
        event.context = 'mParticle logAddToCart'
      })
    }
  }
}

interface IOrderForLogPurchase {
  number: number
  priceCents: number
}

export function logEcomPurchase(product: ICreateProductData, order: IOrderForLogPurchase) {
  const mParticle = getMParticle()
  let revenue = null

  if (!mParticle) {
    return
  }
  const orderSchema = Yup.object({
    number: Yup.number()
      .min(1)
      .required(
        `Order number not supplied to log purchase, unable to log for product: ${product.name}`,
      ),
    priceCents: Yup.number()
      .min(1)
      .required(
        `Price cents(${order.priceCents}) not supplied to mParticle log purchase, order: ${order.number}`,
      ),
  })

  try {
    orderSchema.validateSync(order)
  } catch (e) {
    Bugsnag.notify(e as NotifiableError, (event) => {
      event.context = 'mParticle logEcomPurchase'
    })
    return
  }

  revenue = convertToDollars(order.priceCents)

  try {
    const transactionAttributes = mParticle.eCommerce.createTransactionAttributes(
      order.number,
      null,
      null,
      revenue,
    )
    const mParticleProduct = createProduct(product, mParticle)
    if (mParticleProduct) {
      mParticle.eCommerce.logPurchase(transactionAttributes, mParticleProduct, true, {
        content_type: 'product_group',
      })
    }
  } catch (e) {
    Bugsnag.notify(e as NotifiableError, (event) => {
      event.context = 'mParticle logEcomPurchase'
    })
  }
}
